import { useContext } from "react";
import { KeyDownloadCell } from "../components/KeyDownloadCell";
import { Keys } from "../keys";
import { PGPUseCases } from "../useCases/PGPUseCases";

export const DownloadKeys: React.FC = () => {
  const saveKey = useContext(PGPUseCases).saveKey;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        gap: "1.5em",
      }}
    >
      {Keys.map((key) => (
        <KeyDownloadCell
          key={key.email}
          keyInfo={key}
          onClick={saveKey.download}
        />
      ))}
    </div>
  );
};
