import * as pgp from "openpgp";
import { KeyProps } from "./model/KeyProps";

export let Keys: KeyProps[] = require("./KeyDirectory.json");

Keys.sort((a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
});

const keyFolder = process.env.PUBLIC_URL + "/keys/";

export function getKeyURL(props: KeyProps): string {
  return keyFolder + props.fingerprint + ".asc";
}

export async function getKey(props: KeyProps): Promise<pgp.key.Key> {
  const keyURL = getKeyURL(props);
  const response = await window.fetch(keyURL);

  if (response.ok) {
    const data = await response.text();
    const keys = (await pgp.key.readArmored(data)).keys;
    if (keys.length >= 1) {
      return keys[0];
    } else {
      return Promise.reject(new Error(`Failed to decode key from ${keyURL}. `));
    }
  } else {
    return Promise.reject(
      new Error(
        `Failed to get key from ${keyURL}. ` +
          `Status ${response.status} ${response.statusText}`
      )
    );
  }
}
