import { getKeyURL } from "../keys";
import { KeyProps } from "../model/KeyProps";
import { saveAs } from "file-saver";

export class SaveKeyUseCase {
  /**
   * Fetches the key for the given KeyProps and downloads it to the users disk.
   */
  public async download(key: KeyProps) {
    saveAs(getKeyURL(key), key.filename);
  }
}
