import "./App.css";
import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabPanel, TabList } from "react-tabs";
import { DownloadKeys } from "./sections/DownloadKeys";
import { EncryptFile } from "./sections/EncryptFile";
import { EncryptText } from "./sections/EncryptText";

function App() {
  return (
    <div className="App">
      <div className="textblock">
        <h2>PGP Encryption</h2>
        <p>
          To ensure the security of your sensitive data, we use the PGP
          encryption standard.
        </p>
        <p>
          If you know how to send PGP encrypted emails or would like to verify
          the authenticity of source code commits you received from us, please
          download the PGP key of your contact below.
        </p>
        <p>
          If you have not yet set up PGP email encryption in your email client,
          you can also securely encrypt a file on this page and send it to us
          safely in an unencrypted email.
        </p>
      </div>

      <Tabs>
        <TabList>
          <Tab>Download a key</Tab>
          <Tab>Encrypt text message</Tab>
          <Tab>Encrypt a file</Tab>
        </TabList>
        <TabPanel>
          <DownloadKeys />
        </TabPanel>
        <TabPanel>
          <EncryptText />
        </TabPanel>
        <TabPanel>
          <EncryptFile />
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default App;
