import { useCallback, useContext, useMemo, useState } from "react";
import { Keys } from "../keys";
import { KeyProps } from "../model/KeyProps";
import { useDropzone } from "react-dropzone";
import { PGPUseCases } from "../useCases/PGPUseCases";
import { KeySelectionList } from "../components/KeySelectionList";

export const EncryptFile: React.FC = () => {
  const { encryptFile } = useContext(PGPUseCases);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({ multiple: false });

  const [selectedKeys, setSelectedKeys] = useState([] as KeyProps[]);

  const saveMessage = useCallback(
    () =>
      (async () => {
        const file = acceptedFiles[0];
        encryptFile.download(selectedKeys, file);
      })(),
    [selectedKeys, acceptedFiles, encryptFile]
  );

  const fileUploadContent = useMemo(() => {
    if (acceptedFiles[0]) {
      return (
        <p className="selected-file">Selected file: {acceptedFiles[0].name}</p>
      );
    } else if (isDragActive) {
      return <p>Drop the file here</p>;
    } else {
      return <p>Drag 'n' drop a file here, or click to select file</p>;
    }
  }, [acceptedFiles, isDragActive]);

  return (
    <div>
      <h4>File:</h4>
      <div
        {...getRootProps({ className: "dropzone" })}
        style={{ margin: "0px 0px 1rem" }}
      >
        <input {...getInputProps()} />
        {fileUploadContent}
      </div>
      <h4>Authorized employees:</h4>
      <KeySelectionList keyInfos={Keys} setSelectedKeys={setSelectedKeys} />

      <button
        className="button button--button"
        style={{ margin: "2em 0" }}
        onClick={(e) => {
          if (!e.currentTarget.disabled) {
            saveMessage();
          }
        }}
        disabled={acceptedFiles[0] === undefined || selectedKeys.length <= 0}
      >
        Save encrypted file
      </button>

      <div className="textblock" style={{ margin: "2em 0", color: "gray" }}>
        <p>
          Your file is encrypted offline in your browser and does not leave your
          computer. The encrypted version can only be opened by the authorized
          employees you selected.
        </p>
        <p>
          This is not a contact form. Please send us the encrypted file via
          E-mail or your preferred communication medium.
        </p>
      </div>
    </div>
  );
};
