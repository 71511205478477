import React, { useCallback } from "react";
import { KeyProps } from "../model/KeyProps";
import { KeySelectionCell } from "./KeySelectionCell";

export type KeySelectionListProps = {
  keyInfos: KeyProps[];
  setSelectedKeys: React.Dispatch<React.SetStateAction<KeyProps[]>>;
};

export const KeySelectionList: React.FC<KeySelectionListProps> = ({
  keyInfos,
  setSelectedKeys,
}: KeySelectionListProps) => {
  const onKeySelected = useCallback(
    (keyInfo: KeyProps) =>
      setSelectedKeys((selectedKeys) =>
        selectedKeys.includes(keyInfo)
          ? selectedKeys
          : [...selectedKeys, keyInfo]
      ),
    [setSelectedKeys]
  );
  const onKeyUnselected = useCallback(
    (keyInfo: KeyProps) =>
      setSelectedKeys((selectedKeys) =>
        selectedKeys.filter((k) => k !== keyInfo)
      ),
    [setSelectedKeys]
  );

  return (
    <ul className="keyselection">
      {keyInfos.map((keyInfo) => (
        <KeySelectionCell
          keyInfo={keyInfo}
          onKeySelected={onKeySelected}
          onKeyUnselected={onKeyUnselected}
          key={keyInfo.email}
        />
      ))}
    </ul>
  );
};
