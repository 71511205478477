import { useCallback } from "react";
import { mdiDownloadLock } from "@mdi/js";
import Icon from "@mdi/react";
import { KeyProps } from "../model/KeyProps";
import { ProfilePicture } from "./ProfilePicture";

export type KeyDownloadCellProps = {
  keyInfo: KeyProps;
  onClick: (keyInfo: KeyProps) => void;
};

export const KeyDownloadCell: React.FC<KeyDownloadCellProps> = ({
  keyInfo,
  onClick,
}: KeyDownloadCellProps) => {
  const formatFingerprint = useCallback(() => {
    const components = keyInfo.fingerprint.match(/.{1,4}/g) ?? [];
    return (
      <>
        {components.slice(0, 5).join(" ")}
        <br />
        {components.slice(5, 10).join(" ")}
      </>
    );
  }, [keyInfo.fingerprint]);

  return (
    <button className="key-download-cell" onClick={() => onClick(keyInfo)}>
      <ProfilePicture email={keyInfo.email} />
      <div>
        <div className="name-label">{keyInfo.name}</div>
        <div className="email-label" style={{}}>
          {keyInfo.email}
        </div>
        <div className="fingerprint-label" style={{ color: "#a8acb0" }}>
          {formatFingerprint()}
        </div>
      </div>
      <Icon path={mdiDownloadLock} size={1.5} color={null} />
    </button>
  );
};
