import { getKey } from "../keys";
import { KeyProps } from "../model/KeyProps";
import { saveAs } from "file-saver";
import * as pgp from "openpgp";

export class EncryptTextUseCase {
  /**
   * Encrypt the given string and download it as an encrypted file to the users disk
   * Automatically fetches the keys for the given KeyProps
   */
  public async download(selectedKeys: KeyProps[], message: string) {
    const publicKeys = await Promise.all(
      selectedKeys.map(async (key) => getKey(key))
    );

    const { data: encrypted } = await pgp.encrypt({
      message: pgp.message.fromText(message),
      publicKeys,
    });

    const blob = new Blob([encrypted], {
      type: "application/pgp-encrypted;charset=utf-8",
    });
    const date = new Date();
    saveAs(
      blob,
      "pgp-message-" +
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        "T" +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ".asc"
    );
  }
}
