import { createContext } from "react";
import { SaveKeyUseCase } from "./SaveKeyUseCase";
import { EncryptTextUseCase } from "./EncryptTextUseCase";
import { EncryptFileUseCase } from "./EncryptFileUseCase";

const pgpUseCasesContext = {
  saveKey: new SaveKeyUseCase(),
  encryptText: new EncryptTextUseCase(),
  encryptFile: new EncryptFileUseCase(),
};

export const PGPUseCases = createContext(pgpUseCasesContext);
