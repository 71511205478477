import { getKey } from "../keys";
import { KeyProps } from "../model/KeyProps";
import { saveAs } from "file-saver";
import * as pgp from "openpgp";

export class EncryptFileUseCase {
  /**
   * Encrypt the given file and download it to the users disk
   * Automatically fetches the keys for the given KeyProps
   */
  public async download(selectedKeys: KeyProps[], file: File) {
    const publicKeys = await Promise.all(
      selectedKeys.map(async (key) => getKey(key))
    );
    const fileBuffer = await file.arrayBuffer();
    const fileContent = new Uint8Array(fileBuffer);
    const { data: encrypted } = await pgp.encrypt({
      message: pgp.message.fromBinary(fileContent, file.name),
      publicKeys,
    });
    const blob = new Blob([encrypted], {
      type: "application/pgp-encrypted;charset=utf-8",
    });
    saveAs(blob, file.name + ".asc");
  }
}
