import { KeyProps } from "../model/KeyProps";
import { ProfilePicture } from "./ProfilePicture";

export type KeySelectionCellProps = {
  keyInfo: KeyProps;
  onKeySelected: (keyInfo: KeyProps) => void;
  onKeyUnselected: (keyInfo: KeyProps) => void;
};

export const KeySelectionCell: React.FC<KeySelectionCellProps> = ({
  keyInfo,
  onKeySelected,
  onKeyUnselected,
}: KeySelectionCellProps) => {
  const checkboxChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      onKeySelected(keyInfo);
    } else {
      onKeyUnselected(keyInfo);
    }
  };

  return (
    <li>
      <input type="checkbox" onChange={checkboxChanged} />
      <ProfilePicture email={keyInfo.email} />
      <div>
        <div className="name-label">{keyInfo.name}</div>
        <div className="email-label">{keyInfo.email}</div>
      </div>
    </li>
  );
};
