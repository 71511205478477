import { useCallback } from "react";
import * as crypto from "crypto";

const md5 = (contents: string) =>
  crypto.createHash("md5").update(contents).digest("hex");

export type ProfilePictureProps = {
  email: string;
};

export const ProfilePicture: React.FC<ProfilePictureProps> = ({
  email,
}: ProfilePictureProps) => {
  const gravatarURL = useCallback(() => {
    const emailHash = md5(email.trim().toLowerCase());
    // TODO: Pull default image URL out to some kind of central config when opensourcing for usage
    //       outside of DPM.
    const defaultImage = encodeURIComponent(
      "https://web-pgp-dpm.firebaseapp.com/logo_dpm.png"
    );
    return (
      "https://www.gravatar.com/avatar/" + emailHash + "?s160&d=" + defaultImage
    );
  }, [email]);

  return <img src={gravatarURL()} alt="" />;
};
