import { useCallback, useRef, useState, useContext } from "react";
import { KeySelectionList } from "../components/KeySelectionList";
import { Keys } from "../keys";
import { KeyProps } from "../model/KeyProps";
import { PGPUseCases } from "../useCases/PGPUseCases";

export const EncryptText: React.FC = () => {
  const encryptText = useContext(PGPUseCases).encryptText;
  const messageRef = useRef<HTMLTextAreaElement>(null);

  const [selectedKeys, setSelectedKeys] = useState([] as KeyProps[]);

  const saveMessage = useCallback(
    () =>
      (async () => {
        const message = messageRef.current!.value + "\n";
        encryptText.download(selectedKeys, message);
      })(),
    [selectedKeys, encryptText]
  );

  return (
    <div>
      <h4>Message:</h4>
      <textarea
        ref={messageRef}
        placeholder="Enter your message here"
        rows={5}
        style={{ width: "100%", margin: "0 0 1rem 0" }}
        className="form__message"
        onInput={(e) => {
          e.currentTarget.style.height = "";
          e.currentTarget.style.height = e.currentTarget.scrollHeight + "px";
        }}
      ></textarea>
      <h4>Authorized employees:</h4>
      <KeySelectionList keyInfos={Keys} setSelectedKeys={setSelectedKeys} />

      <button
        className="button button--button"
        style={{ margin: "2em 0" }}
        onClick={(e) => {
          if (!e.currentTarget.disabled) {
            saveMessage();
          }
        }}
        disabled={selectedKeys.length <= 0}
      >
        Save encrypted message
      </button>

      <div className="textblock" style={{ margin: "2em 0", color: "gray" }}>
        <p>
          Your message is encrypted offline in your browser and does not leave
          your computer. The generated file can only be opened by the authorized
          employees you selected.
        </p>
        <p>
          This is not a contact form. Please send us the encrypted file via
          E-mail or your preferred communication medium.
        </p>
      </div>
    </div>
  );
};
